import styled from 'styled-components';
import {media} from 'mixins';

import {ContentSection} from 'components';
import {Gradient, Item, Stack} from 'components/ultra';

export const HomeContent = styled.div``;

export const ContentSectionSteps = styled(ContentSection)``;

export const Steps = styled.ol`
  list-style: none;
  counter-reset: item;
  padding: 0;
  display: flex;
  margin: 4rem 0 0;
  justify-content: space-between;

  ${media.tablet`
    flex-wrap: wrap;
  `};
`;

export const Step = styled.li`
  padding-right: 4rem;
  counter-increment: item;
  display: flex;
  align-items: center;
  flex: 1 0 0;

  ${media.tablet`
    align-items: center;
    padding-right: 0;
    margin-bottom: 3.5rem;
    flex: 0 1 49%;
  `};

  ${media.phone`
    align-items: center;
    flex: 0 1 auto;
  `};

  &::before {
    ${Gradient.blue}
    content: counter(item);
    font-weight: 900;
    font-size: 4rem;
    color: ${props => props.theme.color.white};
    width: 6.3rem;
    height: 6.3rem;
    line-height: 6.3rem;
    text-align: center;
    flex: 0 0 6.3rem;
    margin-right: 1.5rem;
    transform: perspective(100px) rotateY(20deg);
  }

  &:last-of-type {
    padding-right: 0;

    &::before {
      ${Gradient.orange}
    }
  }
`;

export const ContentSectionPricing = styled(ContentSection)``;

export const Price = styled.p`
  font-size: 2.4rem;
  font-weight: 900;
  color: ${({highlight, theme}) =>
    highlight ? theme.color.orange : theme.color.darkBlue};
  text-decoration: ${({strike}) => (strike ? 'line-through' : 'none')};

  & + & {
    margin-top: 0;
  }

  small {
    color: ${({theme}) => theme.color.black};
  }
`;

export const Pricing = styled(Stack)`
  margin-top: 4rem;

  ${media.tablet`
    display: block;
    padding-right: 15%;
    margin-top: 3.5rem;
  `};

  ${media.phone`
    padding-right: 0;
  `};
`;

export const Product = styled(Item)`
  display: flex;
  align-items: flex-start;
  justify-items: baseline;

  ${media.tablet`
    margin-bottom: 3.5rem;
  `};

  > div {
    padding: 1.1rem 0 0 1rem;
  }
`;
