import styled from 'styled-components';

export const DayPickerFields = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Divider = styled.span`
  padding-top: 0.5rem;
  line-height: 2.3rem;
`;
