import React, {useEffect, useRef} from 'react';

import {Content, ContentSection} from 'components';
import Poster3d from 'components/poster3d';
import {Button, Heading} from 'components/ultra';
import poster from 'containers/Home/images/poster-mockup-new.jpg';
import poster2 from 'containers/Home/images/poster2.jpg';
import person from 'containers/Home/images/person2.jpg';
import Sidebar from 'containers/sidebar';
import {useProtection} from 'utilities/auth';
import {useReactRouter} from 'utilities/react-router';

import {ImageSection} from './components';
import {
  ContentSectionSteps,
  ContentSectionPricing,
  Price,
  Pricing,
  Product,
  Steps,
  Step,
} from './styles';

export function Home() {
  const {isAuthenticated} = useProtection();
  const contentRef = useRef(null);
  const {match} = useReactRouter<{page: string}>();

  useEffect(() => {
    const el = document.querySelector(`#${match.params.page}`);
    if (el) {
      el.scrollIntoView();
    } else {
      // "styled-components" innerRef
      contentRef.current.parentNode.scrollTop = 0;
    }
  }, [match.params.page]);

  //const {user} = this.props.data.auth;
  //const buttonLink = user.id ? '/poster' : '/about';
  const buttonLink = isAuthenticated ? '/poster/new' : '/sign-up';

  return (
    <>
      <Sidebar />
      <Content innerRef={contentRef}>
        <ContentSection>
          <Heading element="h1" gradient={true}>
            Motivation for everyone
          </Heading>
          <Heading>Your fitness activities as art</Heading>
        </ContentSection>
        <ImageSection background={poster}>
          <Heading gradient={true}>Let's celebrate your achievements</Heading>
          <p>
            Spurtli turns your sports activities into{' '}
            <strong>beautiful and unique mementos</strong> showcasing your
            achievements.
          </p>
          <p>
            Whether you've just run your first 5&thinsp;km or even the whole
            marathon distance, Spurtli helps you to create a memory that will
            last a lifetime.
          </p>
          <Button to={buttonLink}>Get your memento</Button>
        </ImageSection>
        <ContentSectionSteps>
          <Heading>Four easy steps to get your memento</Heading>
          <Steps>
            <Step>Allow Spurtli to access your STRAVA activities.</Step>
            <Step>
              Spurtli processes and normalizes your most recent fitness
              activities in no time.
            </Step>
            <Step>Start creating your mementos in our memento editor.</Step>
            <Step>
              Buy your memento as a digital copy or get it printed and delivered
              directly.
            </Step>
          </Steps>
        </ContentSectionSteps>
        <ImageSection background={person} order="reverse">
          <Heading gradient={true}>What our users think</Heading>
          <p>
            "With Spurtli I was able to gain new motivation for my daily
            workout. It feels amazing to see how hard I trained throughout the
            year. I am hungry for more!"
          </p>
          <p>
            <strong>Peter E.</strong>
            <br /> Ottawa, Canada
          </p>
        </ImageSection>
        <ContentSectionPricing id="pricing">
          <Heading>Create your mementos, pay as you go</Heading>
          <Pricing gutter>
            <Product>
              <Poster3d color="orange">Free</Poster3d>
              <div>
                <Heading element="h3">Create unlimited mementos</Heading>
                <p>
                  Spurtli is free to use. Sign up and create as many mementos as
                  you like. Save them for later or get a low resolution image of
                  your memento if you do not want to buy one right away.
                </p>
                <Price highlight>free</Price>
              </div>
            </Product>
            <Product>
              <Poster3d sup="Digital">Pro</Poster3d>
              <div>
                <Heading element="h3">Get your digital memento</Heading>
                <p>
                  Created a memento that you like? Get a full resolution digital
                  copy of your desired memento and enjoy the freedom of choosing
                  where to print it.
                </p>
                <Price strike>
                  4,95 €<small> / memento</small>
                </Price>
                <Price highlight>
                  free <small>for now</small>
                </Price>
              </div>
            </Product>
            <Product>
              <Poster3d sup="Print">Pro</Poster3d>
              <div>
                <Heading element="h3">Have your memento printed</Heading>
                <p>
                  Don't feel like heading to a copy shop? This is our care-free
                  package and makes sure that you get your printed memento
                  delivered home.
                </p>
                <Price>coming soon</Price>
              </div>
            </Product>
          </Pricing>
        </ContentSectionPricing>
        <ImageSection background={poster2}>
          <Heading gradient={true}>Choose your own memento</Heading>
          <p>We currently support 4 poster types that can be customized.</p>
          <Button to={buttonLink}>Get your memento</Button>
        </ImageSection>
      </Content>
    </>
  );
}
