import React from 'react';

import {Head, Price, Product, Quantity, TotalPrice} from './styles';

export function CartHeader() {
  return (
    <Head>
      <Product />
      <Price>Price</Price>
      <Quantity>Quantity</Quantity>
      <TotalPrice>Total Price</TotalPrice>
    </Head>
  );
}
