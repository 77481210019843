import React from 'react';
import {Link} from 'react-router-dom';

import {Icon} from 'components/ultra';
import Logo from './images/logo-white.svg';
import PoweredByStrava from './images/powered-by-strava.svg';
import {
  Channel,
  FooterCol,
  FooterRow,
  FooterWrapper,
  LegalLinks,
  Logos,
  SocialMedia,
} from './styles';

export function Footer() {
  return (
    <FooterWrapper>
      <FooterRow>
        <FooterCol>
          <img src={Logo} alt="Spurtli Logo" />
        </FooterCol>
        <FooterCol>
          <h1>About</h1>
          <ul>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/press">Press &amp; media</Link>
            </li>
          </ul>
        </FooterCol>
        <FooterCol>
          <h1>Support</h1>
          <ul>
            <li>
              <a href="https://github.com/spurtli/spurtli">Contributing</a>
            </li>
            <li>
              <Link to="/help">Help</Link>
            </li>
          </ul>
        </FooterCol>
        <FooterCol>
          <h1>Useful Links</h1>
          <ul>
            <li>
              <a href="https://strava.com" target="_blank" rel="noopener">
                Strava
              </a>
            </li>
          </ul>
        </FooterCol>
        <FooterCol noShrink>
          <SocialMedia>
            <Channel>
              <a
                href="http://www.facebook.com/spurtli"
                target="_blank"
                rel="noopener"
              >
                <Icon icon="facebook" transform={{y: 0.5, x: -0.5}} />
              </a>
            </Channel>
            <Channel>
              <a
                href="http://www.twitter.com/spurtli"
                target="_blank"
                rel="noopener"
              >
                <Icon icon="twitter" transform={{y: 0.5, x: 0.5}} />
              </a>
            </Channel>
            <Channel>
              <a
                href="http://www.instagram.com/spurtli"
                target="_blank"
                rel="noopener"
              >
                <Icon icon="instagram" transform={{y: 0.5}} />
              </a>
            </Channel>
          </SocialMedia>
        </FooterCol>
      </FooterRow>
      <FooterRow>
        <FooterCol>
          <LegalLinks>
            <li>
              <Link to="/terms">Terms of Service</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
          </LegalLinks>
        </FooterCol>
        <FooterCol>
          <Logos>
            <img src={PoweredByStrava} />
          </Logos>
        </FooterCol>
      </FooterRow>
    </FooterWrapper>
  );
}
