import styled from 'styled-components';
import {Props} from '../Content';
import {contentHeight} from 'mixins';

export const GridContent = styled.div`
  background-image: linear-gradient(138deg, #f4f6f8 0%, #ffffff 100%);
  grid-area: content;
  overflow: hidden;
  overflow-y: scroll;
`;

export const Main = styled.main<Props>`
  height: ${({fullHeight}) => (fullHeight ? contentHeight() : 'auto')};
  margin-bottom: ${({fullHeight}) => (fullHeight ? '0' : '5rem')};
  max-height: ${({fullHeight}) => (fullHeight ? contentHeight() : 'none')};
  min-height: calc(100vh - ${({theme}) => theme.header.height});
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${({verticalCenter}) =>
    verticalCenter ? 'center' : 'inherit'};
`;
