import React from 'react';

import {TitleSkeleton} from 'components/ultra';
import {formatMoney} from 'utilities/money';

import {Footer, Title, TotalPrice} from './styles';

interface CartFooterProps {
  total: number | null;
}

export function CartFooter({total}: CartFooterProps) {
  const priceMarkup = total != null ? formatMoney(total) : <TitleSkeleton />;
  return (
    <Footer>
      <Title>Total</Title>
      <TotalPrice>{priceMarkup}</TotalPrice>
    </Footer>
  );
}
