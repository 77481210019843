import React, {ReactNode, RefObject} from 'react';
import {Footer} from 'components';
import {Main, GridContent} from './styles/Content';

export interface Props {
  children: ReactNode;
  fullHeight?: boolean;
  verticalCenter?: boolean;
  innerRef?: RefObject<HTMLElement>;
}

export function Content({
  children,
  fullHeight,
  innerRef,
  verticalCenter,
}: Props) {
  return (
    <GridContent>
      <Main
        ref={innerRef}
        fullHeight={fullHeight}
        verticalCenter={verticalCenter}
      >
        {children}
      </Main>
      <Footer />
    </GridContent>
  );
}
