import styled, {ThemedStyledProps} from 'styled-components';
import {Gradient} from 'components/ultra';

interface Poster3dProps {
  color?: string;
  sup?: string;
}

const color = (props: ThemedStyledProps<Poster3dProps, any>) => {
  switch (props.color) {
    case 'orange':
      return Gradient.orange;
    default:
      return Gradient.blue;
  }
};

export const Poster3d = styled.span<Poster3dProps>`
  ${color}
  color: ${props => props.theme.color.white};
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 5rem;
  margin-right: 1rem;
  padding: 0 0.5rem;
  position: relative;
  text-transform: uppercase;
  transform: perspective(100px) rotateY(20deg);
  transition: 0.3s ease-out transform;

  &:after {
    content: '${props => props.sup}';
    background: ${({theme}) => theme.color.darkBlue};
    font-size: 1rem;
    line-height: 1.4rem;
    position: absolute;
    right: -1.2rem;
    top: -0.6rem;
    padding: 0.2rem;
    display: ${props => (props.sup ? 'block' : 'none')};
  }

  &:hover {
    transform: rotateY(0deg);
  }
`;

export default Poster3d;
