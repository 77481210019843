import React from 'react';
import {CirclePicker, CirclePickerProps} from 'react-color';

import {StyledCirclePicker} from './styles/ColorPicker';

export function ColorPicker({color, colors, ...props}: CirclePickerProps) {
  return (
    <StyledCirclePicker>
      <CirclePicker
        {...props}
        color={color == null ? colors[0] : color}
        colors={colors}
        width="auto"
        circleSize={28}
        circleSpacing={10}
      />
    </StyledCirclePicker>
  );
}
