import React from 'react';
import SidebarStyles from './styles';

interface SidebarProps {
  selected?: boolean;
  children?: React.ReactNode;
}

export default function Sidebar(props: SidebarProps) {
  return <SidebarStyles>{props.children}</SidebarStyles>;
}
