import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';
import {media} from '../../../../mixins';

interface ImageSectionProps {
  order?: string;
  background: string;
}

const Text = styled.div`
  position: relative;
  padding: 0 0 0 5rem;
  margin: 6rem 0 5.5rem;
  width: 35%;
  text-align: left;

  ${media.tablet`
    width: 100%;
    padding: 0 3rem;
    margin: 4rem 0 3rem;
  `};
`;

const Image = styled.div`
  position: absolute;
  width: 70%;
  height: 115%;
  right: 0;
  left: auto;
  top: -7%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 30rem 100%);

  ${media.tablet`
    position: relative;
    width: 100%;
    clip-path: none;
    height: 400px;
  `};
`;

const StyledImageSection = styled.section<ImageSectionProps>`
  margin-top: 9rem;
  align-items: center;
  background: ${({theme}) => theme.color.white};
  display: flex;
  min-height: 400px;
  position: relative;
  justify-content: ${({order}) => (order ? 'flex-end' : 'flex-start')};

  &:last-of-type {
    margin-bottom: 3rem;
  }

  ${({order}) =>
    order &&
    css`
      ${Text} {
        padding: 0 5rem 0 0;
        text-align: right;

        ${media.tablet`
          text-align: left;
          padding: 0 3rem;
        `};
      }

      ${Image} {
        right: auto;
        left: 0;
        clip-path: polygon(0% 0%, calc(100% - 30rem) 0%, 100% 100%, 0% 100%);

        ${media.tablet`
          clip-path: none;
        `};
      }
    `};
  ${({background}) =>
    background &&
    css`
      ${Image} {
        background: url(${background}) no-repeat center center;
        background-size: cover;
      }
    `};

  ${media.tablet`
    flex-wrap: wrap;
    margin-top: 3rem;
  `};

  h2 {
    ${media.tablet`
      font-size: 3rem;
      line-height: 3.3rem;
  `};
  }
`;

interface Props {
  children: ReactNode;
  background: string;
  order: string;
}

export function ImageSection({children, background, order}: Props) {
  return (
    <StyledImageSection order={order} background={background}>
      <Image />
      <Text>{children}</Text>
    </StyledImageSection>
  );
}
