import React, {ChangeEvent} from 'react';
import {useMutation} from '@apollo/client';
import {Link} from 'react-router-dom';

import {TitleSkeleton} from 'components/ultra';
import {formatMoney} from 'utilities/money';

import {
  Action,
  EmptyMessage,
  Item,
  Price,
  Product,
  Quantity,
  QuantityInput,
  Thumbnail,
  Text,
  Title,
  TotalPrice,
} from './styles';

interface Props {
  items: any[];
  onUpdateItem(): void;
  onRemoveItem(): void;
}

export function CartItems({items, onUpdateItem, onRemoveItem}: Props) {
  const updateCartItemLoading = false;
  const removeCartItemLoading = false;

  if (!items.length) {
    return (
      <EmptyMessage>You don't have any items in your cart yet.</EmptyMessage>
    );
  }
  return (
    <div>
      {items.map(item => {
        if (removeCartItemLoading) {
          return <CartItemSkeleton />;
        }
        if (item.reference == null) {
          return null;
        }
        return (
          <Item key={item.reference.id}>
            <Product>
              <Thumbnail>
                <Link to={`poster/${item.reference.id}`}>
                  <img src={item.reference.image.url} />
                </Link>
              </Thumbnail>
              <Text>
                <Title>
                  <Link to={`poster/${item.reference.id}`}>
                    {item.reference.name}
                  </Link>
                </Title>
                <Action
                  onClick={() =>
                    onRemoveItem({variables: {cartItemId: item.id}})
                  }
                  size="small"
                >
                  Remove
                </Action>
              </Text>
            </Product>
            <Price>{formatMoney(item.product.price)}</Price>
            <Quantity>
              <QuantityInput
                type="number"
                defaultValue={String(item.quantity)}
                min="1"
                onChange={(evt: ChangeEvent<HTMLInputElement>): void => {
                  const value = Number(evt.target.value);
                  if (value <= 0) {
                    alert('invalid number');
                    return null;
                  }
                  onUpdateItem({
                    variables: {
                      cartItemId: item.id,
                      quantity: value,
                    },
                  });
                }}
                required
              />
            </Quantity>
            <TotalPrice>
              {updateCartItemLoading ? (
                <TitleSkeleton small />
              ) : (
                formatMoney(item.product.price * item.quantity)
              )}
            </TotalPrice>
          </Item>
        );
      })}
    </div>
  );
}

interface CartItemSkeletonProps {
  quantity?: number;
}

export function CartItemSkeleton({quantity = 1}: CartItemSkeletonProps) {
  const items = [...Array(quantity).keys()].map(index => (
    <Item key={index}>
      <Product>
        <Thumbnail>
          <TitleSkeleton />
        </Thumbnail>
        <Text>
          <Title>
            <TitleSkeleton />
          </Title>
        </Text>
      </Product>
      <Price>
        <TitleSkeleton small />
      </Price>
      <Quantity>
        <TitleSkeleton small />
      </Quantity>
      <TotalPrice>
        <TitleSkeleton small />
      </TotalPrice>
    </Item>
  ));

  return <div>{items}</div>;
}
