import styled from 'styled-components';
import {Button, FormInput} from 'components/ultra';

const CartRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.3rem 1rem;
`;

export const Head = styled(CartRow)`
  border-bottom: 0.1rem solid ${({theme}) => theme.color.orange};
  font-weight: 600;
  margin-bottom: 2rem;
  padding-bottom: 0.7rem;
  padding-top: 0;
`;

export const Item = styled(CartRow)`
  align-items: center;
  border-bottom: 0.1rem solid #ccc;

  &:last-of-type {
    border: none;
  }
`;

export const CartCol = styled.div`
  flex: 0 0 25%;
`;

export const Product = styled(CartCol)`
  align-items: center;
  display: flex;
  flex: 1 0 25%;
`;

export const Title = styled.div`
  font-weight: 600;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${({theme}) => theme.color.darkGray};
    }
  }
`;

export const Text = styled.div``;

export const Quantity = styled(CartCol)`
  flex-basis: 12rem;
  display: flex;
  justify-content: center;
`;

export const QuantityInput = styled(FormInput)`
  margin-top: 1.5rem;
  text-align: center;
`;

export const Action = styled(Button)`
  margin: 0.8rem 0rem 0;
`;

export const Price = styled(CartCol)`
  display: flex;
  flex-basis: 12rem;
  justify-content: flex-end;
`;

export const TotalPrice = styled(Price)`
  display: flex;
  flex-basis: 12rem;
  font-weight: 600;
  justify-content: flex-end;
`;

export const Thumbnail = styled.div`
  margin-right: 2rem;
  position: relative;

  a {
    &:after {
      color: ${({theme}) => theme.color.white};
      content: 'VIEW IN EDITOR';
      bottom: 0;
      background-color: ${({theme}) => theme.color.orange};
      display: block;
      left: 0;
      opacity: 0;
      padding-top: 5rem;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      transition: opacity 0.2s ease-out;
    }

    &:hover {
      &:after {
        opacity: 0.8;
      }
    }
  }

  img {
    display: block;
    height: 150px;
    width: auto;
  }

  div {
    height: 150px;
    width: 106px;
  }
`;

export const Footer = styled(CartRow)`
  align-content: flex-end;
  border-top: 0.1rem solid ${({theme}) => theme.color.orange};
  font-weight: 600;
  justify-content: flex-end;
  margin-bottom: 3rem;
  margin-top: 2rem;
  padding: 3rem 0 0 1rem;

  ${TotalPrice}, ${Title} {
    flex: 0 1 auto;
    padding: 3rem 1rem 0;
  }

  ${Title} {
    padding-right: 5rem;
  }
`;

export const EmptyMessage = styled.div`
  margin: 4rem 0;
  text-align: center;
`;
