import styled from 'styled-components';
import {Stack, Item} from 'components/ultra';
import {media} from 'mixins';

export const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.orange};
  clip-path: polygon(0% 0%, calc(100% - 20rem) 0%, 100% 100%, 0% 100%);
  color: ${props => props.theme.color.white};
  padding: 5rem 5rem 2rem;

  ${media.tablet`
    clip-path: none;
    padding: 5rem 3rem 2rem;
  `};

  a {
    color: ${props => props.theme.color.white};
  }
`;

export const FooterRow = styled(Stack)`
  justify-content: space-between;
  margin-right: 16rem;

  &:not(:first-of-type) {
    margin-top: 0.8rem;
  }

  ${media.tablet`
    margin-right: 0;
  `};
`;

export const FooterCol = styled(Item)`
  padding-right: 4rem;

  &:last-of-type {
    padding-right: 0;
  }

  img {
    width: 120px;
    height: auto;
  }

  ul {
    padding: 0;
    list-style: none;
    font-size: 1.6rem;

    li {
      margin-bottom: 0.5rem;
    }
  }

  a {
    text-decoration: none;
  }

  h1 {
    margin-top: 0.9rem;
    font-size: 2rem;
    color: ${props => props.theme.color.darkOrange};
  }
`;

export const SocialMedia = styled.ul`
  padding: 0;
  margin: 0;
  text-align: right;
`;

export const Channel = styled.li`
  display: inline-block;
  background: ${props => props.theme.color.darkOrange};
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  text-align: center;
  margin-right: 0.8rem;

  &:hover {
    cursor: pointer;

    a {
      color: ${props => props.theme.color.white};
    }
  }

  a {
    font-size: 2.5rem;
    line-height: 4rem;
    color: ${props => props.theme.color.orange};
  }
`;

export const LegalLinks = styled.ul`
  font-size: 1.4rem;
  list-style: none;
  padding: 0;

  li {
    margin-right: 1rem;
    display: inline-block;

    a {
      text-decoration: underline;
      color: ${props => props.theme.color.darkOrange};
    }
  }
`;

export const Logos = styled.div`
  margin-top: 0.8rem;
  text-align: right;
`;
