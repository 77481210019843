import styled from 'styled-components';
import {media} from '../../mixins';

export const ContentSection = styled.section<{
  footerWidth?: boolean;
  bottomSpacing?: boolean;
}>`
  max-width: ${({footerWidth}) => (footerWidth ? 'calc(80% - 5rem)' : 'none')};
  margin: 9rem 5rem ${({bottomSpacing}) => (bottomSpacing ? '9rem' : '0')};

  ${media.tablet`
    max-width: none;
    margin: 5rem 3rem 0;
  `};

  ${media.phone`
    margin: 4rem 3rem 0;
  `};
`;
