import React from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {DayPickerProps} from 'react-day-picker/types';
import {formatDate, parseDate} from 'react-day-picker/moment';
import {Scalars} from 'gql/types';
import {Label} from 'components/ultra';
import {DayPickerFields, Divider} from './styles/DayPicker';
import './styles/style.css';

type Iso8601DateTime = Scalars['ISO8601DateTime'];

interface Props {
  from: Iso8601DateTime;
  to: Iso8601DateTime;
  onChange(date: Iso8601DateTime): void;
}

export function DayPicker({from, to, onChange}: Props) {
  let toRef: DayPickerInput;
  const pattern = 'YYYY-MM-DDTHH:mm:ss';
  const formattedStartDateFromProp = moment(from, pattern).toDate();
  const formattedEndDateFromProp = moment(to, pattern).toDate();

  const defaultDayPickerProps: DayPickerProps = {
    firstDayOfWeek: 1,
    numberOfMonths: 2,
    selectedDays: [
      formattedStartDateFromProp,
      {
        from: formattedStartDateFromProp,
        to: formattedEndDateFromProp,
      },
    ],
  };

  const handleFromChange = (from: Date) => {
    // TODO: fehlerbehandlung fehlt, z.B. 20188
    onChange({
      activitiesStartDate: moment(from).utc().startOf('day').toISOString(),
    });
  };

  const handleToChange = (to: Date) => {
    // TODO: fehlerbehandlung fehlt, z.B. 20188
    onChange({
      activitiesEndDate: moment(to).utc().endOf('day').toISOString(),
    });
  };

  return (
    <>
      <Label>From – to</Label>
      <DayPickerFields>
        <DayPickerInput
          value={formattedStartDateFromProp}
          placeholder="From"
          format="L"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            ...defaultDayPickerProps,
            disabledDays: {after: formattedEndDateFromProp},
            toMonth: formattedEndDateFromProp,
            onDayClick: () => toRef.getInput().focus(),
          }}
          onDayChange={handleFromChange}
        />
        <Divider>-</Divider>
        <DayPickerInput
          ref={el => (toRef = el)}
          value={formattedEndDateFromProp}
          placeholder="To"
          format="L"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            ...defaultDayPickerProps,
            disabledDays: {before: formattedStartDateFromProp},
            month: formattedStartDateFromProp,
            fromMonth: formattedStartDateFromProp,
          }}
          onDayChange={handleToChange}
        />
      </DayPickerFields>
    </>
  );
}
